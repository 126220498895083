import {navigate} from "gatsby";
import React, {useEffect} from "react";
import {logoutUser} from "../utils/auth";

const Logout = (props) => {
    useEffect(() => {
        logoutUser();
        navigate('/');
    }, []);

    return <h6>Logging out...</h6>
}


export default Logout;