import {getCookie, eraseCookie} from './cookies';

export const USER_COOKIE_NAME = "USER_AUTH";

export const getUserFromCookie = () => {
    return JSON.parse(getCookie(USER_COOKIE_NAME));
}

export const isUserLoggedIn = () => {
    if (typeof window !== 'undefined') {
        return !!getCookie(USER_COOKIE_NAME);
    }
}

export const logoutUser = () => {
    if (typeof window !== 'undefined') {
        eraseCookie(USER_COOKIE_NAME)
    }
}